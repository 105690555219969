import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/packages/website/src/components/DocsLayout.tsx";
import ControllerMorph from "../../content/examples/controller-morph.mdx";
import Ensemble from "../../content/examples/ensemble.mdx";
import QuickStart from "../../content/examples/quick-start.mdx";
import TrackLevelEasing from "../../content/examples/track-level-easing.mdx";
import LiveReload from "../../content/examples/live-reload.mdx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This page contains playgrounds for various animations used throughout the site. Edit the code to see how they change!`}</p>
    <h2 {...{
      "id": "controller-morph"
    }}>{`Controller Morph`}</h2>

    <ControllerMorph mdxType="ControllerMorph" />
    <h2 {...{
      "id": "ensemble"
    }}>{`Ensemble`}</h2>

    <Ensemble mdxType="Ensemble" />
    <h2 {...{
      "id": "quick-start"
    }}>{`Quick Start`}</h2>

    <QuickStart mdxType="QuickStart" />
    <h2 {...{
      "id": "track-level-easing"
    }}>{`Track-Level Easing`}</h2>

    <TrackLevelEasing mdxType="TrackLevelEasing" />
    <h2 {...{
      "id": "dynamic-reload"
    }}>{`Dynamic Reload`}</h2>

    <LiveReload mdxType="LiveReload" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      